import { Pipe, PipeTransform } from '@angular/core';
import { Device } from '../interfaces/device';

@Pipe({name: 'deviceicon'})
export class DeviceiconPipe implements PipeTransform {
    transform(device: Device): string {
        const icons = {
            // platform only
            browser: "desktop",
            android: "logo-android",
            ios: "logo-apple",

            // other
            mobile: "phone-portrait",
            tablet: "tablet-portrait",
            undefined: "browsers"
        };

        if (device.platform == "browser" || device.platform == "undefined") {
            if (device.userAgent.match(/android/i)) {
                return icons.android;
            }

            if (device.userAgent.match(/iPhone|iPad/i)) {
                return icons.ios;
            }

            if (device.userAgent.match(/iPad|Tablet/i)) {
                return icons.tablet;
            }

            if (device.userAgent.match(/mobile/i)) {
                return icons.mobile;
            }
        }

        return icons[device.platform] || icons.undefined;
    }
}