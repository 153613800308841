import { NgModule } from '@angular/core';
import { DatetimePipe } from './datetime.pipe';
import { DeviceiconPipe } from './deviceicon.pipe';
import { OptioncolorPipe } from './optioncolor.pipe';

@NgModule({
	declarations: [
        DatetimePipe,
        DeviceiconPipe,
        OptioncolorPipe
    ],
	imports: [],
	exports: [
        DatetimePipe,
        DeviceiconPipe,
        OptioncolorPipe
    ]
})
export class PipesModule {}