import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'datetime'})
export class DatetimePipe implements PipeTransform {
    transform(stamp: any): string {
        var today = new Date();
        var datetime = new Date(stamp)

        var comparison = [
            {
                label: "Today",
                type: "day",
                diff: 0
            },
            {
                label: "Yesterday",
                type: "day",
                diff: 1
            }
        ];

        for (var i = 0; i < comparison.length; i++) {
            var item = comparison[i];

            switch (item.type) {
                case "day":
                    if (datetime.getFullYear() === today.getFullYear() && datetime.getMonth() === today.getMonth() && datetime.getDate() >= (today.getDate() - item.diff)) {
                        return item.label +" "+ datetime.toLocaleTimeString();
                    }
                break;
            }
        }

        return datetime.toLocaleDateString() +" "+ datetime.toLocaleTimeString();
    }
}